
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import axios from 'axios';
import {
  getComponent, getConfigEnv, getView,
} from '@/utils/helpers';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
  },
})
export default class ActionBuiler extends Vue {
  showActionBuilder = false;

  actions: any = [];

  action: any = {};

  dataLoading: boolean = false;

  deleteActionPopup = false;

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  async mounted() {
    await this.fetchActions();
  }

  async confirmDeleteAction() {
    // console.log(this.action)
    await metaDataModule.deleteActionBuilder({ id: this.action.ID });
    this.action = {};
    this.deleteActionPopup = false;
    await this.fetchActions();
  }

  async deleteActionBuilder(action) {
    // console.log(action)
    this.action = action;
    this.deleteActionPopup = true;
  }

  async fetchActions() {
    this.dataLoading = true;
    this.showActionBuilder = false;
    let actions = await metaDataModule.getActionsBuilder();
    actions = actions.reduce((r, a) => {
      // console.log(a.Category);
      if (!a.Category || a.Category === '') {
        a.Category = 'None';
      }
      r[a.Category] = r[a.Category] || [];
      r[a.Category].push(a);
      return r;
    }, Object.create(null));
    console.log(actions);
    this.actions = Object.keys(actions).map((key) => ({ title: key, actions: actions[key] }));
    console.log(this.actions);
    this.dataLoading = false;
  }

  async prepareEditAction(action) {
    try {
      this.dataLoading = true;
      // const url: any = action.BuildDetailsUrl;
      const actionPayload: any = await metaDataModule.getActionsBuilderRecords({ actionBuilderID: action.ID });
      // console.log(actionPayload)
      // console.log(url);
      // const request = new Request();
      // console.log(actionDetails[0].Result)
      // const data = await fetch(url, { method: 'GET' });
      // const actionPayload = JSON.parse(actionDetails[0].Result);
      // console.log(Object.keys(actionPayload));
      this.action = actionPayload;
      // this.action.actionPayload = actionPayload;
      this.showActionBuilder = true;
      this.dataLoading = false;
    } catch (error) {
      console.error(error);
    }
  }
}

